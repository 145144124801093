import { isEmpty } from "./array";

export const objectIncludes = (
  object: Record<string, unknown>,
  key: string
): boolean => Object.keys(object).includes(key);

export const objectIsEmpty = (object: Record<string, unknown>): boolean =>
  isEmpty(Object.keys(object));

export const purgeObject = <T>(
  object: Record<string, T | null | undefined>
): Record<string, T> => {
  const newObject = { ...object };
  Object.keys(object).forEach((key) => {
    if (object[key] === undefined || object[key] === null)
      delete newObject[key];
  });

  return newObject as Record<string, T>;
};
