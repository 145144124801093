import React, { FC } from "react";

import FormConstructor, { FormProps } from "components/FormConstructor";
import { fieldMapper } from "constants/form";

const Form: FC<FormProps> = (props) => (
  <FormConstructor fieldMapper={fieldMapper} {...props} />
);

export default Form;
